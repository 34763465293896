import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { Observable } from 'rxjs';
import { EclCrisis, EclCrisisType } from '../../models/ecl-crisis';
import {
  CloseEclCrisis,
  CreateEclCrisis,
  CreateManyCrisisTasks,
  CreateOneCrisisTask,
  CreateOneEclAnnouncement,
  CreateOneEclSummary,
  DeleteOneEclSummary,
  InitEclAnnouncementList,
  InitEclAnnouncementListFromPolling,
  InitEclCrisis,
  InitEclCrisisListFromPolling,
  InitEclSummaryList,
  InitEclSummaryListFromPolling,
  InitEclUserFunctionList,
  RemoveOneCrisisTask,
  SelectEclCurrentCrisis,
  UpdateManyEclCrisis,
  UpdateManyEclSummary,
  UpdateOneCrisisTask,
  UpdateOneEclAnnouncement,
  UpdateOneEclCrisis,
  UpdateOneEclSummary,
} from './crisis.action';
import { EclAnnouncement } from '../../models/ecl-annoucement';
import { CommonStoreManager } from '../../../common/store/common.store-manager';
import { EclSummary } from '../../models/ecl-summary';
import { EclUserFunction } from '../../models/ecl-function';

import { EclCrisisTask } from '../../models/ecl-crisis-task-ref';
import { map, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EclCrisisStoreManager {
  $eclCrisisTypes: Observable<EclCrisisType[]>;
  $eclCrisisList: Observable<EclCrisis[]>;
  $eclSelectedCrisis: Observable<EclCrisis>;
  $eclAnnoucements: Observable<EclAnnouncement[]>;
  $eclSummaries: Observable<EclSummary[]>;
  $eclUserFunction: Observable<EclUserFunction[]>;

  private _lastTaskModified: Observable<EclCrisisTask>;

  constructor(private store: Store<AppState>, private commonStoreManager: CommonStoreManager) {
    this.$eclCrisisTypes = store.select('ecl', 'crisisState', 'crisisTypes');
    this.$eclCrisisList = store.select('ecl', 'crisisState', 'crisis');
    this.$eclSelectedCrisis = store.select('ecl', 'crisisState', 'selectedCrisis');
    this.$eclAnnoucements = store.select('ecl', 'crisisState', 'announcements');
    this.$eclSummaries = store.select('ecl', 'crisisState', 'summaries');
    this.$eclUserFunction = store.select('ecl', 'crisisState', 'userFunction');

    this._lastTaskModified = this.store.select('ecl', 'crisisState', 'tasks').pipe(
      pairwise(),
      map(data => {
        const oldTaskList = data[0];
        const newTaskList = data[1];
        const taskWithNewStatus = newTaskList.filter(newTask =>
          oldTaskList.some(oldTask => {
            return (
              newTask.objectId === oldTask.objectId &&
              newTask.status !== oldTask.status &&
              ['DONE', 'FROZEN', 'NOT_APPLICABLE'].includes(newTask.status)
            );
          }),
        );
        if (taskWithNewStatus && taskWithNewStatus.length === 1) {
          return taskWithNewStatus[0];
        }
      }),
    );
  }

  initState(crisisList: EclCrisis[], typeList: EclCrisisType[], allCurrentUserFunction: EclUserFunction[], crisisIdSaved: string) {
    this.store.dispatch(
      new InitEclCrisis({
        crisisList: crisisList,
        crisisTypeList: typeList,
        allCurrentUserFunction: allCurrentUserFunction,
        crisisIdSaved: crisisIdSaved,
      }),
    );
  }

  createCrisis(newCrisis: EclCrisis) {
    this.store.dispatch(new CreateEclCrisis(newCrisis));
  }

  changeSelectedCrisis(crisis: EclCrisis) {
    this.store.dispatch(new SelectEclCurrentCrisis(crisis));
    this.commonStoreManager.initEclCrisis(crisis);
  }

  closeCrisis(crisis: EclCrisis) {
    this.store.dispatch(new CloseEclCrisis(crisis));
  }

  initCrisisFromDashboardPolling(crisisList: EclCrisis[]) {
    this.store.dispatch(new InitEclCrisisListFromPolling(crisisList));
  }

  createOneAnnouncement(annoucement: EclAnnouncement) {
    this.store.dispatch(new CreateOneEclAnnouncement(annoucement));
  }

  upadteOneAnnouncement(annoucement: EclAnnouncement) {
    this.store.dispatch(new UpdateOneEclAnnouncement(annoucement));
  }

  initEclAnnouncementList(annoucements: EclAnnouncement[]) {
    this.store.dispatch(new InitEclAnnouncementList(annoucements));
  }

  initEclUserFunctionList(userFunction: EclUserFunction[]) {
    this.store.dispatch(new InitEclUserFunctionList(userFunction));
  }

  initEclAnnouncementListFromDashboardPolling(annoucements: EclAnnouncement[]) {
    this.store.dispatch(new InitEclAnnouncementListFromPolling(annoucements));
  }

  createOneSummary(summary: EclSummary) {
    this.store.dispatch(new CreateOneEclSummary(summary));
  }

  deleteOneSummary(summary: EclSummary) {
    this.store.dispatch(new DeleteOneEclSummary(summary));
  }

  upadteOneSummary(summary: EclSummary) {
    this.store.dispatch(new UpdateOneEclSummary(summary));
  }

  updateManySummary(summaries: EclSummary[]) {
    this.store.dispatch(new UpdateManyEclSummary(summaries));
  }

  initEclSummaryList(summaries: EclSummary[]) {
    this.store.dispatch(new InitEclSummaryList(summaries));
  }

  initEclSummaryListFromDashboardPolling(summaries: EclSummary[]) {
    this.store.dispatch(new InitEclSummaryListFromPolling(summaries));
  }

  updateOneCrisis(openedCrisis: EclCrisis) {
    this.store.dispatch(new UpdateOneEclCrisis(openedCrisis));
  }

  updateCrisisFromDashboardPolling(changeCrisis: EclCrisis[]) {
    this.store.dispatch(new UpdateManyEclCrisis(changeCrisis));
  }

  get lastTaskModified(): Observable<EclCrisisTask> {
    return this._lastTaskModified;
  }

  createOneCrisisTask(crisisTask: EclCrisisTask): void {
    this.store.dispatch(new CreateOneCrisisTask(crisisTask));
  }

  createManyCrisisTasks(crisisTasksArray: EclCrisisTask[]): void {
    this.store.dispatch(new CreateManyCrisisTasks(crisisTasksArray));
  }

  updateOneCrisisTask(crisisTask: EclCrisisTask): void {
    this.store.dispatch(new UpdateOneCrisisTask(crisisTask));
  }

  removeOneCrisisTask(crisisTaskObjectId: string): void {
    this.store.dispatch(new RemoveOneCrisisTask(crisisTaskObjectId));
  }
}
