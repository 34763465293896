import { Injectable } from '@angular/core';
import { TasksService } from '../../../common/services/tasks.service';
import { HolTask } from '../../../common/models/hol-task';
import { HolTag } from '../../../common/models/hol-tag';
import { RequestService } from '../../../common/services/request.service';
import { EclHistoryService } from '../ecl-history-service/ecl-history.service';
import { EclTagsService } from '../ecl-tags-service/ecl-tags.service';
import { EclTaskTagService } from '../ecl-task-tag.service';
import { EclCrisisTask, EclCrisisTaskRef, EclCrisisUserTask } from '../../models/ecl-crisis-task-ref';
import { EclCrisis } from '../../models/ecl-crisis';
import { isEmpty, orderBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ErpCrisisTask, ErpCrisisUserTask } from '../../../erp/models/erp-crisisTask';
import { EclFunctionStoreManager } from '../../store/function/function.store-manager';
import { RolesService } from '../../../common/services/roles.service';
import { TagTypeColor } from '../../../common/enums/hol-tag-type-color.enum';
import { TagTypesPrefix } from 'src/app/common/enums/hol-tag-types-prefix.enum';

@Injectable({
  providedIn: 'root',
})
export class EclCrisisTaskService extends TasksService {
  protected ParseCrisis = Parse.Object.extend('ECLCrisis');
  protected ParseTag = Parse.Object.extend('ECLTag');
  protected ParseTask = Parse.Object.extend('ECLTask');
  protected ParseTaskRef = Parse.Object.extend('ECLTask_REF');
  protected ParseTaskTag = Parse.Object.extend('ECLTaskTag');
  protected ParseUser = Parse.Object.extend('_User');

  constructor(
    protected requestService: RequestService,
    protected historyService: EclHistoryService,
    protected eclTagsService: EclTagsService,
    protected crisisTaskTagService: EclTaskTagService,
    protected functionStoreManager: EclFunctionStoreManager,
    protected rolesService: RolesService,
  ) {
    super(requestService, historyService, eclTagsService, crisisTaskTagService);
  }

  async getCrisisTasksRefByCrisisTypeId(): Promise<EclCrisisTaskRef[]> {
    const crisisTaskQuery = new Parse.Query(this.ParseTaskRef);
    crisisTaskQuery.include('ACL');
    crisisTaskQuery.ascending('createdAt');
    crisisTaskQuery.limit(5000);

    const resultBrut = await this.requestService.performFindAllQuery(crisisTaskQuery);
    if (resultBrut.length === 0) {
      return resultBrut.map(item => {
        return new EclCrisisTaskRef(item);
      });
    } else {
      return [];
    }
  }

  async createTaskForCrisis(crisis: EclCrisis): Promise<EclCrisisTask[]> {
    if (crisis.erpType === null || !crisis.erpType.crisisTypeId) {
      throw new Error('Crisis type is null');
    }

    const crisisTaskQuery = new Parse.Query(this.ParseTaskRef);
    crisisTaskQuery.include('ACL');
    crisisTaskQuery.equalTo('crisisTypeId', crisis.erpType.crisisTypeId);
    crisisTaskQuery.ascending('createdAt');
    crisisTaskQuery.limit(5000);

    const resultBrut = await this.requestService.performFindAllQuery(crisisTaskQuery);
    if (resultBrut) {
      // Extraire les tags uniques
      const uniqueTags = Array.from(
        new Set(
          resultBrut
            .map(obj => (obj.get('defaultTags') ? obj.get('defaultTags') : ''))
            .reduce<string[]>((acc, tags) => acc.concat(tags.split('|')), []),
        ),
      );

      const existingTag = await this.eclTagsService.getAllTagByCrisis(crisis);
      const tagStringToAdd = uniqueTags.filter(tag => !existingTag.find(t => t.name === tag));

      const tagPromise = tagStringToAdd.map(tag => {
        const color = (() => {
          switch (tag.substring(0, 1)) {
            case TagTypesPrefix.status:
              return TagTypeColor.status;
            case TagTypesPrefix.department:
              return TagTypeColor.department;
            case TagTypesPrefix.stakeholder:
              return TagTypeColor.stakeholder;
            default:
              return TagTypeColor.remaining;
          }
        })();
        return this.eclTagsService.create(color, tag, crisis, true);
      });

      const newTag = await Promise.all(tagPromise);

      existingTag.push(...newTag);
      //Copie dans la base

      const eclCrisisTaskRef = resultBrut.map(item => {
        const listStringTag = item.get('defaultTags') ? item.get('defaultTags').split('|') : [];
        const defaultTags = this.getDefaultTagsForTask(listStringTag, existingTag);
        return new EclCrisisTaskRef(item, [], defaultTags);
      });
      const parseToSaved = eclCrisisTaskRef.map(item => {
        return this.taskRefToTaskParseObject(item, crisis);
      });

      const savedObject = await this.requestService.performSaveAllQuery(parseToSaved);

      const listCrisisTask = savedObject.map(item => {
        // const eclTaskRef = eclCrisisTaskRef.find(ref => ref.code === item.get('code') && ref.functionId == item.get('functionId') && ref.order == item.get('order') && ref.acl == item.getACL() && ref.crisisTypeId == item.get('crisisTypeId'));
        const listStringTag = item.get('defaultTags') ? item.get('defaultTags').split('|') : [];
        const defaultTags = this.getDefaultTagsForTask(listStringTag, existingTag);
        return new EclCrisisTask(item, [], defaultTags);
      });

      /*
      for (const task of listCrisisTask) {
        let parseTags;
        if (uniqueTags) {
          const crisisTaskTags = task.defaultTags
            .map(tag => {
              if (tag) {
                return new this.ParseTaskTag({
                  task: new this.ParseTask({ id: task.objectId }),
                  tag: new this.ParseTag({ id: tag.objectId }),
                });
              }
              // Si le tag n'est pas trouvé, retourne null (sera filtré ensuite)
              return null;
            })
            .filter(parseTaskTag => parseTaskTag !== null || parseTaskTag != '');

          parseTags = await this.requestService.performSaveAllQuery(crisisTaskTags);
        }

      }
      */
    } else {
      return [];
    }
  }

  canChangeAcl(task: HolTask) {
    return false;
  }

  isAttachmentsMandatory(task: HolTask) {
    return false;
  }

  newTask(parseObject?: Parse.Object, tags?: HolTag[], defaultTags?: HolTag[]): HolTask {
    return new EclCrisisTask(parseObject, tags, defaultTags);
  }

  protected taskToParseObject(task: EclCrisisTaskRef | EclCrisisTask): Parse.Object {
    const parseObject = super.taskToParseObject(task);

    if (task instanceof EclCrisisTask) {
      parseObject.set('crisis', task.crisis ? new this.ParseCrisis({ id: task.crisis.objectId }) : null);
    }
    parseObject.set('outputDataLabel', task.outputDataLabel);
    parseObject.set('customOutputDataLabel', task.customOutputDataLabel);
    parseObject.set('crisisTypeId', task.crisisTypeId);
    parseObject.set('isDocumentOnly', task.isDocumentOnly);
    parseObject.set('frozenByErd', task.frozenByErd);
    parseObject.set('customVisibleBy', task.customVisibleBy);
    parseObject.set('visibleBy', task.visibleBy);
    return parseObject;
  }

  protected taskRefToTaskParseObject(task: EclCrisisTaskRef, crisis: EclCrisis): Parse.Object {
    let parseObject;
    parseObject = new this.ParseTask();
    parseObject.set('createdBy', Parse.User.current());
    parseObject.set('crisis', crisis ? new this.ParseCrisis({ id: crisis.objectId }) : null);

    parseObject.set('outputDataLabel', task.outputDataLabel);
    parseObject.set('customOutputDataLabel', task.customOutputDataLabel);
    parseObject.set('crisisTypeId', task.crisisTypeId);
    parseObject.set('isDocumentOnly', task.isDocumentOnly);
    parseObject.set('frozenByErd', task.frozenByErd);
    parseObject.set('customVisibleBy', task.customVisibleBy);
    parseObject.set('visibleBy', task.visibleBy);
    if (task.acl) {
      if (isEmpty(task.acl.permissionsById)) {
        task.acl.setPublicReadAccess(true);
        task.acl.setPublicWriteAccess(true);
      }
      parseObject.setACL(task.acl);
    }
    parseObject.set('outputTitle', task.outputTitle);
    parseObject.set('code', task.code);
    parseObject.set('order', task.order);
    parseObject.set('subOrder', task.subOrder);
    parseObject.set('status', task.status);
    parseObject.set('functionId', task.functionId);
    parseObject.set('updatedBy', Parse.User.current());
    parseObject.set('taskTitle', task.taskTitle);
    parseObject.set('taskDescription', task.taskDescription);
    parseObject.set('formIoFormRef', task.formIoFormRef);
    parseObject.set('formIoFormSubmission', task.formIoFormSubmission);
    parseObject.set('attachments', task.attachments);
    // parseObject.set('visibilityByFunctionId', crisisTask.visibilityByFunctionId);
    // parseObject.set('createdByFunctionId', crisisTask.createdByFunctionId);
    parseObject.set('nextInfoTime', task.nextInfoTime);
    parseObject.set('nextInfoDone', task.nextInfoDone);
    parseObject.set('comment', task.comment);
    parseObject.set('customVisibleBy', task.customVisibleBy);
    parseObject.set('isFunctionNotified', task.isFunctionNotified);
    parseObject.set('defaultTags', task.defaultTags.map(t => t.name).join('|'));
    if (task.customCreatedAt) {
      parseObject.set('customCreatedAt', task.customCreatedAt);
    }

    return parseObject;
  }

  getTasksByUser(crisis: EclCrisis): Observable<EclCrisisUserTask> {
    const $crisisTasksByUserSubject: BehaviorSubject<EclCrisisUserTask> = new BehaviorSubject(undefined);
    combineLatest([
      this.rolesService.$companiesRolesFilter,
      this.functionStoreManager.$userFunctions,
      this.functionStoreManager.$eclFunctionState,
      this.functionStoreManager.allTasks,
    ]).subscribe(([roles, functionsUser, functionsState, allTasks]) => {
      if (functionsUser && functionsUser.length && functionsState && functionsState.allUserFunctions && allTasks && allTasks.length) {
        const eclCrisisTasks = this.rolesService.filterFromCompanyRoles(
          allTasks.filter(t => t && !t.isDocumentOnly && t.crisis.objectId == crisis.objectId),
        );
        functionsUser = this.rolesService.filterFromCompanyRoles(functionsUser);
        const functionsCrisis = this.rolesService.filterFromCompanyRoles(functionsState.functions);
        const buffercrisisTasksByUser: EclCrisisUserTask = {
          tasks: orderBy(
            eclCrisisTasks.filter(t => !t.isDocumentOnly && functionsUser.findIndex(f => f.functionId === t.functionId) > -1),
            [item => (item.status && item.status !== 'DONE' ? 0 : 1), 'nextInfoTime', 'order', 'subOrder', 'code'],
            ['asc', 'asc', 'asc', 'asc', 'asc'],
          ),
          functionInfos: functionsUser
            .map(fUser => {
              const tempFCrisis = functionsCrisis.find(fCrisis => fCrisis.functionId === fUser.functionId);
              if (tempFCrisis && fUser) {
                return {
                  shortTitle: tempFCrisis.shortTitle,
                  title: tempFCrisis.title,
                  functionId: tempFCrisis.functionId,
                  isHolder: fUser.isHolder,
                };
              }
            })
            .filter(el => el !== undefined),
        };
        $crisisTasksByUserSubject.next(buffercrisisTasksByUser);
      } else {
        $crisisTasksByUserSubject.next({ tasks: [], functionInfos: [] });
      }
    });
    return $crisisTasksByUserSubject;
  }

  async getAllTasks() {
    const crisisTaskQuery = new Parse.Query(this.ParseTask);
    crisisTaskQuery.include('ACL');
    crisisTaskQuery.includeAll();
    crisisTaskQuery.ascending('createdAt');
    crisisTaskQuery.limit(5000);

    const crisisTaskTagsQuery = new Parse.Query(this.ParseTaskTag);
    crisisTaskTagsQuery.include('tag');
    crisisTaskTagsQuery.descending('createdAt');
    crisisTaskTagsQuery.matchesQuery('task', crisisTaskQuery);

    const [crisisTasksFromApi, crisisTasksTags, crisisTags] = await Promise.all([
      this.requestService.performFindAllQuery(crisisTaskQuery),
      this.requestService.performFindAllQuery(crisisTaskTagsQuery),
      this.eclTagsService.getAll(false),
    ]);

    if (crisisTasksFromApi) {
      return crisisTasksFromApi.map(crisisTaskFromApi => {
        const tags = this.getTagsForTask(crisisTasksTags, crisisTaskFromApi);
        const defaultTagCodes: string[] = crisisTaskFromApi.get('defaultTags') ? crisisTaskFromApi.get('defaultTags').split('|') : [];
        const defaultTags: HolTag[] = this.getDefaultTagsForTask(defaultTagCodes, crisisTags);
        return new EclCrisisTask(crisisTaskFromApi, tags && tags.map(t => new HolTag(t.get('tag'))), defaultTags);
      });
    } else {
      return [];
    }
  }
}
