import { OclEvent } from './ocl-event.model';
import { HolNextInfo } from '../../common/models/hol-next-info.model';
import moment from 'moment';
import { OclGroup } from './ocl-group.model';

export class OclEventGroup<T extends OclEvent = OclEvent> extends OclGroup<T> {
  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.crisis = parseObject.get('crisis'); // Add this line to assign the 'crisis' property

    this.items =
      parseObject.get('events') && parseObject.get('events').length ? parseObject.get('events').map(event => this.newItem(event)) : [];
  }

  public get getMostCriticalNextInfo(): HolNextInfo | null {
    let mostRecent: HolNextInfo = null;
    this.items.forEach(event => {
      if (event.infos) {
        event.infos.forEach(info => {
          if (!info.done) {
            if (mostRecent === null) {
              mostRecent = info;
            }

            if (moment(info.nextInfoTime).isBefore(mostRecent.nextInfoTime)) {
              mostRecent = info;
            }
          }
        });
      } else {
        mostRecent = null;
      }
    });

    return mostRecent;
  }

  protected newItem(parseObject?: Parse.Object): T {
    return new OclEvent(parseObject) as T;
  }
}
